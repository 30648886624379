<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <b-form-group
          label="Filter"
          :label-for="`${prefix}-filter-input`"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input
              :id="`${prefix}-filter-input`"
              v-model="filter"
              type="search"
              placeholder="Type to Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      responsive
      v-if="showNewView"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
      :tbody-tr-class="rowClass"
      :fields="tableFields.new"
      :items="rows">
      <template v-slot:cell(dpt)="data">
        <b-badge v-if="data.item.dpt" variant="success">active</b-badge>
        <b-badge v-else variant="danger">not active</b-badge>
      </template>
      <template v-slot:cell(dpt_technical_launch_date)="data">
        <span v-if="data.item.dpt_technical_launch_date">{{ data.item.dpt_technical_launch_date }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:cell(bloom)="data">
        <b-badge v-if="data.item.bloom" variant="success">active</b-badge>
        <b-badge v-else variant="danger">not active</b-badge>
      </template>
      <template v-slot:cell(bloom_technical_launch_date)="data">
        <span v-if="data.item.bloom_technical_launch_date">{{ data.item.bloom_technical_launch_date }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:cell(mind_technical_launch_date)="data">
        <span v-if="data.item.mind_technical_launch_date">{{ data.item.mind_technical_launch_date }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:cell(move)="data">
        <b-badge v-if="data.item.move" variant="success">active</b-badge>
        <b-badge v-else variant="danger">not active</b-badge>
      </template>
      <template v-slot:cell(move_technical_launch_date)="data">
        <span v-if="data.item.move_technical_launch_date">{{ data.item.move_technical_launch_date }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:cell(dpt_go)="data">
        <b-badge v-if="data.item.dpt_go" variant="success">active</b-badge>
        <b-badge v-else variant="danger">not active</b-badge>
      </template>
      <template v-slot:cell(dpt_go_technical_launch_date)="data">
        <span v-if="data.item.dpt_go_technical_launch_date">{{ data.item.dpt_go_technical_launch_date }}</span>
        <span v-else>-</span>
      </template>
    </b-table>
    <b-table
      responsive
      v-else
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      stacked="md"
      show-empty
      small
      @filtered="onFiltered"
      :tbody-tr-class="rowClass"
      :fields="tableFields.old"
      :items="rows">
      <template v-slot:cell(is_active)="data">
        <b-badge v-if="data.item.is_active" variant="success">active</b-badge>
        <b-badge v-else variant="danger">not active</b-badge>
      </template>
    </b-table>
    <b-row>
      <b-col>
        <b-pagination
          style="margin-left: 60%"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0">
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'EligibilityFailuresEligibilityFile',
  props: {
    rows: {
      type: Array,
      default: () => ({}),
    },
    perPage: {
      type: Number,
      default: 10,
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableFields: {
        old: [
          { key: 'aso_group', label: 'Code' },
          { key: 'is_active', label: 'Active', sortable: true },
          { key: 'start_date', label: 'Start Date', sortable: true },
        ],
        new: [
          { key: 'aso_group', label: 'Code' },
          { key: 'dpt', label: 'DPT Active', sortable: true },
          { key: 'dpt_technical_launch_date', label: 'DPT Technical Launch Date', sortable: true },
          { key: 'bloom', label: 'Bloom Active', sortable: true },
          { key: 'bloom_technical_launch_date', label: 'Bloom Technical Launch Date', sortable: true },
          { key: 'mind', label: 'Mind Active', sortable: true },
          { key: 'mind_technical_launch_date', label: 'Mind Technical Launch Date', sortable: true },
          { key: 'move', label: 'Move Active', sortable: true },
          { key: 'move_technical_launch_date', label: 'Move Technical Launch Date', sortable: true },
          { key: 'dpt_go', label: 'DPT GO Active', sortable: true },
          { key: 'dpt_go_technical_launch_date', label: 'DPT GO Launch Date', sortable: true },
        ],
      },
      details: {},
      currentPage: 1,
      totalRows: this.rows.length,
      filter: null,
      filterOn: [],
      showNewView: true,
    };
  },
  beforeMount() {
    if (this.rows.length > 0 && Object.keys(this.rows[0]).includes('is_active')) {
      this.showNewView = false;
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return;
      }

      if (!this.showNewView) {
        if (!item.is_active) {
          // eslint-disable-next-line consistent-return
          return 'table-danger';
        }
      } else if (!item.dpt || !item.bloom || !item.move || !item.dpt_go || !item.mind) {
        // eslint-disable-next-line consistent-return
        return 'table-danger';
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
