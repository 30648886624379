<template>
  <b-container class="p-4" v-if="loaded">
    <b-row>
      <b-col class="mb-2 d-flex align-items-center justify-content-between">
        <h2>Attempt Details</h2>
      </b-col>
      <b-col v-if="!notFoundError && details.processed === 1">
        <b-button v-b-tooltip.hover
                  title="Re-run this attempt"
                  size="bg" variant="outline-primary"
                  @click.prevent="refreshAttempt"
                  :disabled="submitting"
                  class="float-right"
                  v-b-modal.modal-edit-license>
          Re-run this attempt
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div id="outer" class="container" v-if="notFoundError || details.processed === 0">
          <div id="inner">
            <h4 v-if="notFoundError">This attempt doesn't exist or you don't have access to it.</h4>
            <h4 v-else>This entry is currently being processed. Try again in a few minutes</h4>
            <b-button v-b-tooltip.hover
                      title="Back"
                      size="bg" variant="outline-primary"
                      style="margin-left: 40%; margin-top: 5%"
                      @click.prevent="$router.push('/onboarding/eligibility-failures')"
                      v-b-modal.modal-edit-license>
              <feather type="skip-back"></feather>
              Go Back
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <b-card class="eligibility-history-details" v-if="!notFoundError && details.processed === 1">
          <b-row>
            <b-col cols="6">
              <b-col cols="12" class="mt-3">
                <h3>Best Finding</h3><p>(Source: Eligibility File)</p>
              </b-col>
              <div v-if="details.info.configs.eligibility_file">
                <div class="card-body pt-0" v-if="!isNullOrEmpty(details.best_match)">
                  <p class="card-text mt-0 mb-0"><strong>First Name:</strong><span
                    :style="getColorFromMatchValue(details.best_match.details._details.info.first_name.match)">
                    {{ details.best_match.details.first_name }}</span>
                  </p>
                  <p class="card-text mt-0 mb-0"><strong>Last Name:</strong> <span
                    :style="getColorFromMatchValue(details.best_match.details._details.info.last_name.match)">
                    {{ details.best_match.details.last_name }}</span>
                  </p>
                  <p class="card-text mt-0 mb-0"><strong>Email:</strong> <span
                    :style="getColorFromMatchValue(details.best_match.details._details.info.email.match)">
                    {{ details.best_match.details.email }}</span>
                  </p>
                  <p class="card-text mt-0 mb-0"><strong>Date of Birth:</strong> <span
                    :style="getColorFromMatchValue(details.best_match.details._details.info.date_of_birth.match)">
                    {{ details.best_match.details.date_of_birth }}</span>
                  </p>
                  <p class="card-text mt-0 mb-0"><strong>Program Types:</strong>
                    <span :style="getColorFromMatchValue(details.best_match.details._details.info.program_type.match)">
                      {{
                        details.best_match.details._details.info.program_type.found
                          ? details.best_match.details._details.info.program_type.found
                          : 'None'
                      }}
                    </span>
                  </p>
                  <p class="card-text mt-0 mb-0"><strong>ASO Group:</strong>
                    <span :style="getColorFromMatchValue(details.best_match.details._details.info.aso_group.match)">
                      {{
                        details.best_match.details._details.info.aso_group.found
                          ? details.best_match.details._details.info.aso_group.found
                          : 'None'
                      }}
                    </span>
                  </p>
                  <p class="card-text mt-0 mb-0"><strong>System Message:</strong> {{ details.best_match.details._details.message }}</p>
                  <p class="card-text mt-0 mb-0"><strong>Details:</strong></p>
                  <MatchNotes class="ml-0" style="padding-left: 4%" :notes="details.best_match.details._details.notes"/>
                </div>
                <div class="card-body pt-0" v-else>
                  <span>No match found for current revision(s)</span>
                </div>
              </div>
              <div v-else>
                <div class="card-body pt-0">
                  <span>Eligibility File Disabled</span>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <b-card-body>
                <b-row>
                  <h3>Information Submitted</h3>
                </b-row>
                <div class="mb-3">
                  <p class="card-text mt-0 mb-0"><strong>First Name:</strong> {{details.first_name}}</p>
                  <p class="card-text mt-0 mb-0"><strong>Last Name:</strong> {{details.last_name}}</p>
                  <p class="card-text mt-0 mb-0"><strong>Email:</strong> {{details.email}}</p>
                  <p class="card-text mt-0 mb-0"><strong>Date of Birth:</strong> {{ formatDate(details.birthdate, 'YYYY-MM-DD')}}</p>
                  <p class="card-text mt-0 mb-0"><strong>Attempted At:</strong> {{ formatDate(details.created_at, 'YYYY-MM-DD HH:mm')}}</p>
                  <p class="card-text mt-0 mb-0" v-if="!isNullOrEmpty(details.excluded_reason)">
                    <strong>Excluded Reason:</strong> {{ details.excluded_reason }}
                  </p>
                </div>
                <b-row>
                  <h3>Tracking Information</h3>
                </b-row>
                <div v-if="!isNullOrEmpty(details.tracking_info)">
                  <p v-for="(key, idx) in Object.keys(details.tracking_info)" :key="idx" class="card-text mt-0 mb-0">
                    <strong>{{getTrackingLabel(key)}}:</strong> {{details.tracking_info[key]}}
                  </p>
                </div>
                <div v-else>
                  <p> No Tracking Information</p>
                </div>
                <p v-if="!isNullOrEmpty(details.program_type)"><strong>Program Type:</strong> {{getProgramType(details.program_type)}}</p>
                <b-row class="mt-3">
                  <h3>Eligibility Systems</h3>
                </b-row>
                <div class="ml-1">
                  <p class="mt-0 mb-0"><b>Eligibility File</b>:
                    <BooleanBadge :value="details.info.configs.eligibility_file === 1"/>
                  </p>
                  <p class="mt-0 mb-0"><b>Availity</b>:
                    <BooleanBadge :value="details.info.configs.availity === 1"/>
                  </p>
                  <p class="mt-0 mb-0"><b>Solera</b>:
                    <BooleanBadge :value="details.info.configs.solera === 1"/>
                  </p>
                  <p class="mt-0 mb-0"><b>Sharecare</b>:
                    <BooleanBadge :value="details.info.configs.sharecare === 1"/>
                  </p>
                  <p class="mt-0 mb-0"><b>Whitelist</b>:
                    <BooleanBadge :value="details.info.configs.whitelist === 1"/>
                  </p>
                </div>
                <b-row class="mt-3">
                  <h3>System Notes</h3>
                </b-row>
                <div v-if="details.info.notes.length > 0">
                  <div class="card-body pt-0 pl-0">
                    <p class="card-text mt-0 mb-0" v-for="(note, id) in details.info.notes" :key="id">{{note}}</p>
                  </div>
                </div>
                <div v-else class="card-body pt-0">
                  <p class="card-text mt-0 mb-0">No notes.</p>
                </div>
              </b-card-body>
            </b-col>
          </b-row>
          <hr>
          <b-col cols="12" class="mt-2">
            <h3>Analysis Result</h3>
            <i class="ml-1">Only enabled for Eligibility File and Whitelist</i>
          </b-col>
          <b-row>
            <b-col cols="12">
              <div class="card-body pt-2 mt-3">
                <h4>Eligibility File</h4>
                <EligibilityFile v-if="details.info.configs.eligibility_file" :details="details.info.eligibility_file"/>
                <span v-else>Eligibility File Disabled</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import EligibilityFile from '@/components/EligibilityFailures/EligibilityFile.vue';
import BooleanBadge from '@/components/EligibilityFailures/BooleanBadge.vue';
import axios from 'axios';
import { required } from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import moment from 'moment-timezone';
import { mapGetters } from 'vuex';
import { isNullOrEmpty } from '@/helpers';
import MatchNotes from '@/components/EligibilityFailures/MatchNotes.vue';

export default {
  name: 'EligibilityAttemptsDetails',
  components: {
    MatchNotes,
    BooleanBadge,
    EligibilityFile,
  },
  data() {
    return {
      details: null,
      loaded: false,
      user_status_options: [
        { value: null, text: 'Select an option' },
        { value: 'resolved', text: 'Resolved' },
        { value: 'processing', text: 'Processing' },
        { value: 'ignored', text: 'Ignored' },
      ],
      form: {
        user_notes: '',
        user_status: null,
        flagged: false,
      },
      submitting: false,
      notFoundError: false,
    };
  },
  mixins: [ errorHandler ],
  validations() {
    return {
      form: {
        user_status: { required },
        flagged: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
    }),
  },
  async beforeMount() {
    if (!this.isSftpSettingsEnabled) {
      this.$router.push('/not-found');
      return;
    }

    await this.getAttempt();
  },
  methods: {
    async getAttempt() {
      this.loaded = false;
      try {
        const { data } = await axios.get(`v1/sftp/eligibility-failures/${this.$route.params.uuid}`);
        // eslint-disable-next-line prefer-destructuring
        this.details = data;
      } catch (err) {
        if (err.response.status === 404) {
          this.notFoundError = true;
          return;
        }

        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.loaded = true;
      }
    },
    async refreshAttempt() {
      this.submitting = true;
      try {
        await axios.patch(`v1/sftp/eligibility-failures/${this.$route.params.uuid}/refresh`);
        await this.getAttempt();
        this.$noty.success('Attempt scheduled to be refreshed');
      } catch (err) {
        if (err.response.status === 404) {
          this.notFoundError = true;
          return;
        }

        console.error(err);
        this.$noty.error(err?.response?.data?.message || 'Something went wrong');
      } finally {
        this.submitting = false;
      }
    },
    formatDate(date, format) {
      const parsedDate = moment(date);
      if (!parsedDate.isValid()) {
        return '-';
      }

      return parsedDate.format(format);
    },
    isNullOrEmpty,
    getColorFromMatchValue(match) {
      switch (match) {
        case 'success':
          return 'color: #128000';
        case 'threshold':
          return 'color: #ff4a00';
        case 'failure':
          return 'color: red';
        default:
          return '';
      }
    },
    getTrackingLabel(key) {
      switch (key) {
        case 'utm_medium':
          return 'Medium';
        case 'utm_source':
          return 'Source';
        case 'utm_content':
          return 'Content';
        case 'utm_campaign':
          return 'Campaign';
        default:
          console.warn('Unknown tracking key', key);
          return key;
      }
    },
    getProgramType(val) {
      switch (val.toLowerCase()) {
        case 'virtual_pt':
          return 'DPT';
        case 'bloom':
          return 'Bloom';
        case 'move':
          return 'Move';
        default:
          console.warn('Unknown program type', val);
          return val;
      }
    },
  },
};
</script>

<style scoped>
  .eligibility-history-details {
    border: none;
  }

  .eligibility-history-details > .card-body {
    padding-left: 0;
    padding-top: 0;
  }

  #outer {
    width: 100%;
    height: 70vh;
    display: flex;
  }

  #inner {
    margin: auto;
  }
</style>

<style>
  .remove-font-weight > label {
    font-weight: normal !important;
  }
</style>
