<template>
  <div>
    <dl class="row" v-for="(_, key) in fields" :key="key">
      <dt class="col-sm-6">{{key}}</dt>
      <dd class="col-sm-6" v-if="key !== 'program_type' && key !== 'is_dependent'">{{fields[key] || '-'}}</dd>
      <dd class="col-sm-6" v-else-if="key === 'is_dependent'">{{fields[key] === 1 ? 'Yes' : 'No'}}</dd>
      <div class="col-sm-6" v-else>
        <b-row v-for="[key, value] in Object.entries(fields[key])" :key="key">
          <dt class="col-sm-9">{{key}}</dt>
          <dd class="col-sm-3">{{value ? 'Yes' : 'No'}}</dd>
        </b-row>
      </div>
    </dl>
  </div>
</template>

<script>
import { omit } from 'ramda';

export default {
  name: 'EligibilityFileInformationModal',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ignored_fields: [
        'payload',
        '_details',
        '_showDetails',
        'imported_at',
      ],
      fields: {},
      close: false,
    };
  },
  beforeMount() {
    this.fields = omit(this.ignored_fields, this.data);
  },
};
</script>

<style scoped>
  dt, dd {
    word-break: break-all;
  }
</style>
