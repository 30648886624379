<template>
  <b-col cols="12">
    <div class="card mt-4">
      <div :id="`accordion-eligibility-history`">
        <div class="card-header" :id="`heading-eligibility-history-${data.revision.revision}`">
          <p class="mb-0" data-toggle="collapse" :data-target="`#collapse-eligibility-history-${data.revision.revision}`" aria-expanded="false"
             :aria-controls="`collapse-eligibility-history-${data.revision.revision}`">
            <feather type="arrow-down-circle" class="align-middle"></feather>
            <span class="align-middle">
              Revision: {{ data.revision.revision }}. Active from {{ data.revision.active_since }} until {{ data.revision.active_until }}
            </span>
          </p>
        </div>
        <div
          :id="`collapse-eligibility-history-${data.revision.revision}`"
          class="mb-0 collapse"
          :aria-labelledby="`heading-eligibility-history-${data.revision.revision}`"
          :data-parent="`#accordion-eligibility-history`">
          <div class="card-body">
            <h5 class="mb-3 mt-0">Metadata</h5>
            <p class="ml-3"><b>Filename</b>: {{ data.revision.filename }}</p>
            <p class="ml-3 mt-0"><b>Revision Number</b>: {{ data.revision.revision }}</p>
            <p class="ml-3 mt-0"><b>Active Since</b>: {{ data.revision.active_since }}</p>
            <p class="ml-3 mt-0"><b>Active Until</b>: {{ data.revision.active_until }}</p>
            <p class="ml-3 mt-0"><b>Next Filename</b>: {{ data.revision.next_filename }}</p>
            <p class="ml-3 mt-0"><b>Next Revision</b>: {{ data.revision.next_revision }}</p>
            <h5 class="mb-3 mt-4">List</h5>
            <EligibilityFileResultList :items="data.findings" :prefix="`eligibility-history-${data.revision.revision}`"/>
          </div>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import EligibilityFileResultList from '@/components/EligibilityFailures/EligibilityFileResultList.vue';

export default {
  name: 'EligibilityFileHistoryFinding',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    EligibilityFileResultList,
  },
};
</script>
