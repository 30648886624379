<template>
  <b-col cols="12" class="pl-0">
    <div class="card mt-4">
      <div class="mb-0">
        <div class="card-body">
          <EligibilityFileDetails :details="details"/>
          <div v-if="Object.keys(details.siblings).length > 0">
            <hr>
            <h4 class="mb-3 mt-4">Siblings Result</h4>
            <div class="card mt-4">
              <div class="mb-0">
                <div class="card-body pt-0">
                  <div class="card mt-4" v-for="(sibling, idx) in details.siblings" :key="idx">
                    <div :id="'siblings-accordion-' + idx">
                      <div class="card-header" :id="'siblings-heading' + idx">
                        <p class="mb-0" data-toggle="collapse" :data-target="'#siblings-collapse' + idx" aria-expanded="true"
                           :aria-controls="'siblings-collapse' + idx">
                          <feather type="arrow-down-circle" class="align-middle"></feather>
                          <span class="align-middle"> Client ID: {{ idx }} (Matches: {{sibling.matches}})</span>
                        </p>
                      </div>
                      <div :id="'siblings-collapse' + idx" class="mb-0 collapse" :aria-labelledby="'siblings-heading' + idx"
                           :data-parent="'#siblings-accordion-' + idx">
                        <b-col cols="12">
                          <div class="card mt-4" style="border: none">
                            <div class="mb-0">
                              <div class="card-body">
                                <EligibilityFileDetails :details="sibling" :prefix="`sibling-${idx}`"/>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-col>
</template>

<script>
import EligibilityFileDetails from '@/components/EligibilityFailures/EligibilityFileDetails.vue';

export default {
  name: 'EligibilityFailuresEligibilityFile',
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    EligibilityFileDetails,
  },
};
</script>
