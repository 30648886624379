<template>
  <b-badge :variant="getBadge()">{{ getMessage() }}</b-badge>
</template>

<script>
export default {
  name: 'BooleanBadge',
  props: {
    value: {
      default: true,
    },
  },
  methods: {
    getBadge() {
      switch (this.value) {
        case true:
          return 'success';
        case false:
          return 'danger';
        default:
          console.warn('Invalid value for boolean badge', this.value);
          return 'warning';
      }
    },
    getMessage() {
      switch (this.value) {
        case true:
          return 'enabled';
        case false:
          return 'disabled';
        default:
          console.warn('Invalid value for boolean badge', this.value);
          return 'Invalid';
      }
    },
  },
};
</script>
