<template>
  <div>
    <h5 class="mb-3">Notes</h5>
    <ul class="list-group" v-if="details.info.length > 0">
      <li v-for="(r, id) in details.info" :key="id" class="list-group-item">{{ r }}</li>
    </ul>
    <p v-else class="ml-1">No notes</p>

    <h5 class="mb-3 mt-4">Errors</h5>
    <ul class="list-group" v-if="details.errors.length > 0">
      <li v-for="(r, id) in details.errors" :key="id" class="list-group-item">{{ r }}</li>
    </ul>
    <p v-else class="ml-1">No errors</p>

    <h5 class="mb-3 mt-4">Warnings</h5>
    <ul class="list-group" v-if="details.warnings.length > 0">
      <li v-for="(r, id) in details.warnings" :key="id" class="list-group-item">{{ r }}</li>
    </ul>
    <p v-else class="ml-1">No warnings</p>
    <hr>
    <h4 class="mb-3 mt-4">File Providers</h4>
    <div class="card mt-4" v-for="(row, id) in details.report" :key="id">
      <div :id="`${prefix}-accordion-${id}`">
        <div class="card-header" :id="`${prefix}-heading-${id}`">
          <p class="mb-0" data-toggle="collapse" :data-target="`#${prefix}-collapse-${id}`"
             aria-expanded="true"
             :aria-controls="`${prefix}-collapse-${id}`">
            <feather type="arrow-down-circle" class="align-middle"></feather>
            <span class="align-middle"> {{ row.metadata.provider.folder }}</span>
          </p>
        </div>
        <div :id="`${prefix}-collapse-${id}`" class="mb-0 collapse"
             :aria-labelledby="`${prefix}-heading-${id}`"
             :data-parent="`#${prefix}-accordion-${id}`">
          <div class="card-body">
            <h5 class="mb-3">Notes</h5>
            <ul class="list-group" v-if="row.info.length > 0">
              <li v-for="(r, id) in row.info" :key="id" class="list-group-item">{{ r }}</li>
            </ul>
            <p v-else class="ml-1">No notes</p>

            <h5 class="mb-3 mt-4">Errors</h5>
            <ul class="list-group" v-if="row.errors.length > 0">
              <li v-for="(r, id) in row.errors" :key="id" class="list-group-item">{{ r }}</li>
            </ul>
            <p v-else class="ml-1">No errors</p>

            <h5 class="mb-3 mt-4">Warnings</h5>
            <ul class="list-group" v-if="row.warnings.length > 0">
              <li v-for="(r, id) in row.warnings" :key="id" class="list-group-item">{{ r }}</li>
            </ul>
            <p v-else class="ml-1">No warnings</p>

            <h5 class="mb-3 mt-4">Metadata</h5>
            <p class="ml-1"><b>Filename</b>: {{ row.metadata.revision.filename }}</p>
            <p class="ml-1 mt-0"><b>Ingested at</b>: {{ row.metadata.revision.ingested_at }}</p>
            <p class="ml-1 mt-0"><b>Revision ID</b>: {{ row.metadata.revision.revision_id }}</p>
            <p class="ml-1 mt-0"><b>Revision Number</b>: {{ row.metadata.revision.revision_number }}</p>
            <p class="ml-1 mt-0"><b>Multi Tenant</b>: <BooleanBadge :value="row.metadata.provider.conditions.aso_groups.enabled"/></p>
            <p class="ml-1 mt-2" v-if="row.metadata.provider.conditions.aso_groups.enabled"><b>Configured ASO Groups</b></p>
            <b-col cols="10" v-if="row.metadata.provider.conditions.aso_groups.enabled">
              <AsoGroupTable :rows="row.metadata.provider.conditions.aso_groups.values" :prefix="prefix"/>
            </b-col>
            <p class="ml-1 mt-0"><b>Employee Term Date Validation</b>:
              <BooleanBadge :value="row.metadata.provider.conditions.employee_term_date_enabled"/></p>
            <p class="ml-1 mt-0"><b>Program Types</b></p>
            <b-row class="ml-1">
              <b-col cols="4" class="program-type-badge">
                <h6><b>Global</b></h6>
                <p class="ml-0 mt-2 mb-0"><b>DPT</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.global.dpt"/></p>
                <p class="ml-0 mt-0 mb-0"><b>Bloom</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.global.bloom"/> </p>
                <p class="ml-0 mt-0 mb-0"><b>Mind</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.global.mind"/> </p>
                <p class="ml-0 mt-0 mb-0"><b>Move</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.global.move"/> </p>
                <p class="ml-0 mt-0 mb-0"><b>Academy</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.global.academy"/> </p>
                <p class="ml-0 mt-0 mb-0"><b>On-Call</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.global.on_call"/> </p>
              </b-col>
              <b-col cols="4" class="program-type-badge">
                <h6><b>File</b></h6>
                <p class="ml-0 mt-2 mb-0"><b>DPT</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.file.dpt"/> </p>
                <p class="ml-0 mt-0 mb-0"><b>Bloom</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.file.bloom"/> </p>
                <p class="ml-0 mt-0 mb-0"><b>Mind</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.file.mind"/> </p>
                <p class="ml-0 mt-0 mb-0"><b>Move</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.file.move"/> </p>
                <p class="ml-0 mt-0 mb-0"><b>Academy</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.file.academy"/> </p>
                <p class="ml-0 mt-0 mb-0"><b>On-Call</b>:
                  <BooleanBadge :value="row.metadata.provider.conditions.program_types.file.on_call"/> </p>
              </b-col>
              <b-col cols="4" class="program-type-badge">
                <b-badge variant="danger"/>
                <h6><b>Disabled</b></h6>
                <p class="ml-0 mt-0 mb-0" v-for="(program, id) in row.metadata.provider.conditions.program_types.disabled" :key="id">
                  <b>{{formatProgramType(program)}}</b>
                </p>
                <p class="ml-0 mt-0 mb-0" v-if="row.metadata.provider.conditions.program_types.disabled.length === 0">
                  <b>None</b>
                </p>
              </b-col>
            </b-row>
            <h4 class="mb-3 mt-4">Current Findings</h4>
            <div v-if="Object.keys(row.findings.current).length > 0">
              <EligibilityFileResultList :items="row.findings.current"
                :prefix="`${prefix}-eligibility-history-${row.metadata.revision.revision_number}`"/>
            </div>
            <span v-else>There are no records to show</span>
            <hr>
            <h4 class="mb-3 mt-4">History Data</h4>
            <div v-if="Object.keys(row.findings.history).length > 0">
              <EligibilityFileHistoryFinding
                  :id="`${prefix}-eligibility-failure-history-finding-${key}`"
                  v-for="(data, key) in row.findings.history"
                  :key="`eligibility-failure-history-finding-${key}`"
                  :data="data"/>
            </div>
            <span v-else>There are no records to show</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AsoGroupTable from '@/components/EligibilityFailures/AsoGroups.vue';
import BooleanBadge from '@/components/EligibilityFailures/BooleanBadge.vue';
import EligibilityFileResultList from '@/components/EligibilityFailures/EligibilityFileResultList.vue';
import EligibilityFileHistoryFinding from '@/components/EligibilityFailures/EligibilityFileHistoryFinding.vue';

export default {
  name: 'EligibilityFailuresEligibilityFile',
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  components: {
    AsoGroupTable,
    BooleanBadge,
    EligibilityFileResultList,
    EligibilityFileHistoryFinding,
  },
  methods: {
    formatProgramType(code) {
      switch (code) {
        case 'dpt':
          return 'DPT';
        case 'bloom':
          return 'Bloom';
        case 'mind':
          return 'Mind';
        case 'move':
          return 'Move';
        case 'academy':
          return 'Academy';
        case 'on_call':
          return 'On-Call';
        default:
          console.warn('Invalid program type code', code);
          return code;
      }
    },
  },
};
</script>

<style scoped>
  .program-type-badge > p {
    font-size: 14px;
  }
</style>
