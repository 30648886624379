<template>
  <b-container fluid>
    <b-table
      :items="items"
      :fields="fields"
      stacked="md"
      show-empty
      small>
      <template #cell(first_name)="row">
        <div :class="getFieldClassFromMatchValue(row.item._details.info.first_name.match)">
          {{ row.item.first_name }}
        </div>
      </template>

      <template #cell(last_name)="row">
        <div :class="getFieldClassFromMatchValue(row.item._details.info.last_name.match)">
          {{ row.item.last_name }}
        </div>
      </template>

      <template #cell(email)="row">
        <div :class="getFieldClassFromMatchValue(row.item._details.info.email.match)">
          {{ row.item.email }}
        </div>
      </template>

      <template #cell(date_of_birth)="row">
        <div :class="getFieldClassFromMatchValue(row.item._details.info.date_of_birth.match)">
          {{ row.item.date_of_birth }}
        </div>
      </template>

      <template #cell(_details.info.aso_group.found)="row">
        <div :class="getFieldClassFromMatchValue(row.item._details.info.aso_group.match)">
          {{ row.item._details.info.aso_group.found ? row.item._details.info.aso_group.found : 'None' }}
        </div>
      </template>

      <template #cell(_details.info.program_type.found)="row">
        <div :class="getFieldClassFromMatchValue(row.item._details.info.program_type.match)">
          {{ row.item._details.info.program_type.found ? row.item._details.info.program_type.found : 'None' }}
        </div>
      </template>

      <template #cell(_details.info.employee_term_date.found)="row">
        <div :class="getFieldClassFromMatchValue(row.item._details.info.employee_term_date.match)">
          {{ row.item._details.info.employee_term_date.found ? row.item._details.info.employee_term_date.found : 'None' }}
        </div>
      </template>

      <template #cell(actions)="row">
        <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
          Show Details
        </b-button>
        <b-button size="sm" @click="row.toggleDetails">
          {{ row.detailsShowing ? 'Hide' : 'Show' }} Notes
        </b-button>
      </template>

      <template #row-details="row">
        <b-card class="notes-card">
          <MatchNotes class="notes-card" :notes="row.item._details.notes"/>
        </b-card>
      </template>
    </b-table>

    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <MemberInformationModal :data="infoModal.content"/>
    </b-modal>
  </b-container>
</template>

<script>
import MemberInformationModal from '@/components/EligibilityFailures/MemberInformationModal.vue';
import MatchNotes from '@/components/EligibilityFailures/MatchNotes.vue';

export default {
  name: 'EligibilityFileResultList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  components: {
    MatchNotes,
    MemberInformationModal,
  },
  data() {
    return {
      fields: [
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'email', label: 'Email' },
        { key: 'date_of_birth', label: 'Date of birth' },
        { key: '_details.info.program_type.found', label: 'Program Types' },
        { key: '_details.info.aso_group.found', label: 'ASO Group' },
        { key: '_details.info.employee_term_date.found', label: 'Employee Term Date' },
        { key: '_details.message', label: 'System Message' },
        { key: 'actions', label: 'Actions' },
      ],
      infoModal: {
        id: `${this.prefix}-info-modal`,
        title: '',
        content: {},
      },
    };
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Member details for row: ${index}`;
      this.infoModal.content = item;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = '';
      this.infoModal.content = {};
    },
    getFieldClassFromMatchValue(match) {
      switch (match) {
        case 'success':
          return 'valid-value';
        case 'threshold':
          return 'warning-value';
        case 'failure':
          return 'invalid-value';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
  .valid-value {
    color: #128000;
  }

  .warning-value {
    color: #ff4a00;
  }

  .invalid-value {
    color: red;
  }

  .notes-card > .card-body {
    padding-left: 0;
    padding-bottom: 0;
  }
</style>
